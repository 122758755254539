/**
 * Logo
 */
 import logo from '../../assets/logo/logo.png'
 import logo_side_word from '../../assets/logo/logo_side_word.png'
 import logo_sub_word from '../../assets/logo/logo_sub_word.png'
 
 /**
  * Background
  */

import * as THEME from '../../assets/theme/color'
 
 
 /**
  * Color Theme
  */
  const ThemeColor = THEME.color
  const ThemeText = THEME.text
 
 const img = {
    logo: logo,
    logo_side_word: logo_side_word,
    logo_sub_word: logo_sub_word,
 }
 
 const color = {
    themeColor: ThemeColor,
    themeText: ThemeText
 }
 
 export const LOGO = img.logo;
 export const LOGO_SIDE_WORD = img.logo_side_word;
 export const LOGO_SUB_WORD = img.logo_sub_word;
 
 export const THEME_COLOR = color.themeColor;
 export const TEXT_COLOUR = color.themeText;