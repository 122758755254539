import * as CONFIG from "../../../config";

export const fms_v1_fe_path = CONFIG.APP_CONFIG.FMS_V1_FE_API_GATEWAY;
export const fms_v1_iot_path = CONFIG.APP_CONFIG.FMS_V1_IOT_API_GATEWAY;
export const slimiot_live_url = CONFIG.APP_CONFIG.GPSWOX_V1_FE_API_GATEWAY;
export const inistate_v1_fe_path = CONFIG.APP_CONFIG.INISTATE_V1_FE_API_GATEWAY;
export const gpswox_user_api_hash = CONFIG.APP_CONFIG.GPSWOX_USER_API_HASH;

export const headers = {
    accept: "*/*",
    // "Content-Type": "application/json; charset=utf-8",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Headers": "*",
    // "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept',
}

export const inistate_headers = {
    'Content-Type': 'application/json', 
    'Authorization': 'fsk d0cb260b-5cf4-4851-8c29-9f3c53352f56:f36d1a6aea304a69b741eb0b31234abf',  
    'wsId': '527' 
}