import * as ActionTypes from "../action-types";

const device_defaultState = {
  byID: {},
  allIDs: [],
};

export const device = (state = device_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_ALL_DEVICE_LIST: {
      // console.log("action for device", action);
      const device = action.device;

      device &&
        device.length &&
        device.map((device) => {
          const id = device.id;

          newState.byID[id] = device;

          if (!newState.allIDs.includes(id)) newState.allIDs.push(id);

          return null;
        });

      return newState;
    }
    case ActionTypes.GET_DEVICE_LATEST: {
      const devices = action.devices;

      devices &&
        devices.length &&
        devices.map((device) => {
          const dvid = device.dvid;

          newState.byID[dvid] = device;

          if (!newState.allIDs.includes(dvid)) newState.allIDs.push(dvid);

          return null;
        });

      return newState;
    }
    default: {
      return state;
    }
  }
};
