export const SET_LOADING_GENERAL = "V1_SET_LOADING_GENERAL";
export const UNSET_LOADING_GENERAL = "V1_UNSET_LOADING_GENERAL";

export const SET_LOADING_USER = "V1_SET_LOADING_USER";
export const UNSET_LOADING_USER = "V1_UNSET_LOADING_USER";

export const SET_LOADING_DEVICE_LIST = "V1_SET_LOADING_DEVICE_LIST";
export const UNSET_LOADING_DEVICE_LIST = "V1_UNSET_LOADING_DEVICE_LIST";

export const SET_LOADING_DEVICE_LATEST = "V1_SET_LOADING_DEVICE_LATEST";
export const UNSET_LOADING_DEVICE_LATEST = "V1_UNSET_LOADING_DEVICE_LATEST";

export const SET_LOADING_BOOKING_LIST = "V1_SET_LOADING_BOOKING_LIST";
export const UNSET_LOADING_BOOKING_LIST = "V1_UNSET_LOADING_BOOKING_LIST";

export const SET_LOADING_BOOKING = "V1_SET_LOADING_BOOKING";
export const UNSET_LOADING_BOOKING = "V1_UNSET_LOADING_BOOKING";
