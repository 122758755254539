import * as ActionTypes from "../action-types/";

export const setLoadingGeneral = () => {
  return {
    type: ActionTypes.SET_LOADING_GENERAL,
  };
};

export const unsetLoadingGeneral = () => {
  return {
    type: ActionTypes.UNSET_LOADING_GENERAL,
  };
};

export const setLoadingAuth = () => {
  return {
    type: ActionTypes.SET_LOADING_AUTH,
  };
};

export const unsetLoadingAuth = () => {
  return {
    type: ActionTypes.UNSET_LOADING_AUTH,
  };
};

export const setLoadingAuthSignup = () => {
  return {
    type: ActionTypes.SET_LOADING_AUTH_SIGNUP,
  };
};

export const unsetLoadingAuthSignup = () => {
  return {
    type: ActionTypes.UNSET_LOADING_AUTH_SIGNUP,
  };
};

export const setLoadingUserAPIHash = () => {
  return {
    type: ActionTypes.SET_LOADING_USER_API_HASH,
  };
};

export const unsetLoadingUserAPIHash = () => {
  return {
    type: ActionTypes.UNSET_LOADING_USER_API_HASH,
  };
};

export const setLoadingBookingList = () => {
  return {
      type: ActionTypes.SET_LOADING_BOOKING_LIST,
  };
};

export const unsetLoadingBookingList = () => {
  return {
      type: ActionTypes.UNSET_LOADING_BOOKING_LIST,
  };
};

