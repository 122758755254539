/**
 * App Info
 */
 export const APP_INFO = {
    APP_VERSION:  process.env.REACT_APP_PROJECT_VERSION,
    APP_MODE: process.env.REACT_APP_PROJECT_MODE,
    APP_MAINTENANCE_MODE: process.env.REACT_APP_PROJECT_MAINTENANCE_MODE,
    APP_PLATFORM: process.env.REACT_APP_PLATFORM,
    COPYRIGHT_YEAR: process.env.REACT_APP_COPYRIGHT_YEAR,
    PROJECT_NAME: process.env.REACT_APP_PROJECT_NAME,
    PUBLICATION_COMPANY: process.env.REACT_APP_PUBLICATION_COMPANY
}

const AWS_AMPLIFY_EXTRA = JSON.parse(process.env.REACT_APP_AWS_AMPLIFY_CONFIG_EXTRA)

/**
 * App Config
 */
export const APP_CONFIG = {
    APP_MASTER_KEY: process.env.REACT_APP_MASTER_KEY,
    EXT_IFRAME: JSON.parse(process.env.REACT_APP_EXT_IFRAME),
    AWS_AMPLIFY: JSON.parse(process.env.REACT_APP_AWS_AMPLIFY_CONFIG), 
    AWS_AMPLIFY_EXTRA: AWS_AMPLIFY_EXTRA,
    FIREBASE: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG), 
    AUTHENTICATION_PROVIDER: process.env.REACT_APP_AUTHENTICATION_PROVIDER,
    APP_GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API,
    FMS_V1_SSO_API_GATEWAY: process.env.REACT_APP_FMS_V1_SSO_API_GATEWAY,
    FMS_V1_FE_API_GATEWAY: process.env.REACT_APP_FMS_V1_FE_API_GATEWAY,
    FMS_V1_IOT_API_GATEWAY: process.env.REACT_APP_FMS_V1_IOT_API_GATEWAY,
    GPSWOX_CONFIG_LIVE_URL: process.env.REACT_APP_GPSWOX_CONFIG_LIVE_URL,
    GPSWOX_CONFIG_MOCK_URL: process.env.REACT_APP_GPSWOX_CONFIG_MOCK_URL,
    GPSWOX_V1_FE_API_GATEWAY: process.env.REACT_APP_GPSWOX_V1_FE_API_GATEWAY,
    GPSWOX_USER_API_HASH: process.env.REACT_APP_GPSWOX_USER_API_HASH,
    INISTATE_V1_FE_API_GATEWAY: process.env.REACT_APP_INISTATE_V1_FE_API_GATEWAY,

}

/**
 * API Constant
 */
export const API_CONSTANT =  {
    headers: {
        accept: '*/*',
        // 'Content-Type': 'application/json; charset=utf-8'
    }
  }

  /**
 * API Base Url
 */
export const GPXWOX_CONFIG = {
    LIVE_URL: `https://tracking.slim-iot.com/api`,
    MOCK_URL: 'https://stoplight.io/mocks/gpswox/tracking-software'
}