// React Thirty Party Library
import { Menu } from "antd";

// Constants Library

// Components Library

// Handler Library

// Services Library

// Redux Actions

// Styles Library

const SidebarMenu = (props) => {
    const { onClick, sidebarItems } = props;

    return (
        <Menu
            style={{color:'white', fontSize: '11px'}}
            mode="inline"
            onClick={onClick}
            items={sidebarItems.map((item) => {
                const { children = [] } = item;

                const MenuComponent = (m) => ({
                    key: m.key,
                    disabled: m.disabled,
                    type: m.type,
                    icon: m.icon,
                    onClick: onClick,
                    label: m.label,
                    style:
                        m.type === "group" ? { position: "absolute", bottom: "20px", width:"100%" } : {},
                });

                return (
                    (
                        children 
                        &&
                        children.length 
                        && 
                        {
                            ...MenuComponent(item),
                            children: item.children.map((m) => MenuComponent(m)),
                        }
                    ) 
                    ||
                    MenuComponent(item)
                );
            })}
        />
    );
};

export default SidebarMenu;
