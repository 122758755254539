import * as ActionTypes from "../action-types";

const user_defaultState = {
    accountPackages: []
};

export const user = (state = user_defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    if (!action) return newState;

    switch (action.type) {
        case ActionTypes.USER_SIGN_IN: {
            let user = action.user;
            return {
                ...newState,
                ...user,
            };
        }
        default: {
            return state;
        }
    }
};
