// React Thirty Party Library

// Constants Library
import { ROOT_CONSTANT } from "../../../../constants";

// const { 
//     SUB,
//     SIDE
// } = ROOT_CONSTANT.IMG.ICON.LOGO;
const { THEME } = ROOT_CONSTANT

const Logo = ({
    collapsed
}) => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', margin: `${collapsed ? 2 : 1.5}vh 0` }}>
            <img
                src={collapsed ? THEME.LOGO_SUB_WORD : THEME.LOGO_SIDE_WORD}
                alt="Drive lah Logo"
                style={{
                    width: collapsed ? '5em' : '10em',
                }}
            />
        </div>
    );
};
export default Logo;
