// Third Party Library
import { Modal, Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

// Constants
import { THEME } from "../../../../../../constants";
const theme_color = THEME.THEME_COLOR;

const ConfirmLogout = (props) => {
  const { isModalOpen, setIsModalOpen, onClick } = props;

  return (
    <>
      <Modal
        centered
        open={isModalOpen}
        width={400}
        onCancel={setIsModalOpen}
        style={{ textAlign: "center"}}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button
              key="logout"
              type="primary"
              style={{ marginRight: "5px" }}
              onClick={onClick}
            >
              Yes, logout
            </Button>
            <Button
              key="cancel"
              onClick={setIsModalOpen}
              style={{ color: "#878787"}}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <LogoutOutlined style={{ color: theme_color, fontSize: "30px" }} />
        <p style={{ fontWeight: 500 }}>Logout</p>
        <p style={{ color: "#878787" }}>
          Are you sure you want to logout from <br />
          CBVV Platform?
        </p>
      </Modal>
    </>
  );
};
export default ConfirmLogout;
