// React Thirty Party Library
import React, { useState } from "react";
import { Layout as LayoutAntd } from "antd";

// Constants Library

// Components Library
import { Content, Header, Sidebar } from "./components/index";
import VehicleTracking from "../pages/VehicleTracking";

// Handler Library

// Services Library

// Redux Actions

// Styles Library

const LayoutContainer = (props) => {
    const [ collapsed, setCollapsed ] = useState(true);

    return (
        <LayoutAntd>
            <Sidebar collapsed={collapsed} />
            
            <LayoutAntd>
                {/* <Header collapsed={collapsed} setCollapsed={setCollapsed} /> */}
                <Content collapsed={collapsed}>{props.children}</Content>
            </LayoutAntd>
        </LayoutAntd>
    );
};

export default LayoutContainer;