import React, { useState } from "react";
import { Form, Input, Button } from "antd";

/**
 * Constant
 */

/**
 * Styles
 */
import "./index.css";

export const LoginForm = (props) => {
  const { company, setCompany, errorMessage } = props;

  // const [company, setCompany] = useState('')

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Form className="login-form" layout="vertical">
        <Form.Item className="login-form-item" label="Company Name">
          <Input
            value={company}
            style={{
              height: "41px",
            }}
            onChange={(e) => setCompany(e.target.value)}
          />
          {errorMessage && (
            <p style={{ color: "red", marginTop: "5px" }}>{errorMessage}</p>
          )}
        </Form.Item>
        <Form.Item className="login-form-item">
          <Button
            style={{
              width: "100%",
              height: "41px",
              border: "none",
              boxShadow: "2px 2px 5px #696969",
              fontSize: 13,
            }}
            type="primary"
            className="login-form-button"
            onClick={() => props.onLoginPress(company)}
            loading={props.isLoginLoading}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

// // Old code
{
  /* <Form.Item className="login-form-item" label="Email Address">
                    <Input
                        value={email}
                        style={{
                            height: '41px'
                        }}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Form.Item>
                <Form.Item className="login-form-item" label="Password">
                    <Input.Password
                        value={password}
                        style={{
                            height: '41px'
                        }}
                        onChange={e => setPassword(e.target.value)}
                    />
                    </Form.Item> */
}
