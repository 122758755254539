import 
    React
from "react";
/**
 * Sub Components
 */
import SSOMaintenancePanel from './components/SSOMaintenancePanel'

/**
 * Config
 */
import * as CONFIG from '../../../config'

/**
 * Constant
 */
import * as ROOT_CONSTANT from '../../../constants'

/**
 * Services
 */

/**
 * Redux Actions
 */


const SSOMaintenance = (props) => {
    
    return (
        <div
            style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                flex: 1,
                alignItems: "center"
            }}
        >
            <div
                style={{
                    position: "absolute",
                    height: "100vh",
                    width: "100%",
                    backgroundColor: `${ROOT_CONSTANT.THEME.THEME_COLOR}`,
                    backgroundSize: "cover"
                }}
            />

            <div
                style={{
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: "35px 30px 15px 30px",
                            borderRadius: "17px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}
                        >
                            <img
                                src={ROOT_CONSTANT.THEME.LOGO}
                                alt={CONFIG.APP_INFO.PUBLICATION_COMPANY}
                                style={{ height: "80px" }}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}
                        >
                            <SSOMaintenancePanel
                                {...props}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: 'black',
                    borderRadius: "17px",
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    color: 'white',
                    position: 'absolute',
                    bottom: '2vh',
                    right: '5vw',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    zIndex: 1
                }}
            >
                 {CONFIG.APP_INFO.PUBLICATION_COMPANY} - {CONFIG.APP_INFO.PROJECT_NAME} - {CONFIG.APP_INFO.APP_VERSION}
            </div>
        </div>
    );
}

export default React.memo(SSOMaintenance)