export const SSO_USER_SIGN_IN = "SSO_USER_SIGN_IN";
export const SSO_USER_SIGN_OUT = "SSO_USER_SIGN_OUT";
export const SSO_USER_RESET_PASSWORD_REQUEST = "SSO_USER_RESET_PASSWORD_REQUEST";
export const SSO_USER_RESET_PASSWORD = "SSO_USER_RESET_PASSWORD";
export const SET_USER_EMAIL_DATA = "SET_USER_EMAIL_DATA";
export const SET_USER_PASSWORD = "SET_USER_PASSWORD";
export const AUTH_USER_SIGN_UP = "AUTH_USER_SIGN_UP";
export const SSO_USER_SIGN_UP = "SSO_USER_SIGN_UP";
export const USER_SIGN_IN_ADD = "USER_SIGN_IN_ADD";
export const USER_SIGN_IN_REMOVE = "USER_SIGN_IN_REMOVE";
