import React, { useCallback, useEffect } from "react";
// import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// Components

// Container
import AppV1 from "../../../v1";

// Container Redux Action
import { v1_user, v1_user_api_hash } from "../../../v1/services/redux/actions";

const SSOPanel = (props) => {

    const { currentUser } = props;
    
    const loadingAuthState = useSelector((state) => state.containers.main.style.loading.auth);

    const user = useSelector((state) => state.containers.main.user)
    const userV1 = useSelector((state) => state.containers.main.user)

    const user_api_hash = useSelector((state) => state.containers.main.user_api_hash)
    const user_api_hashV1 = useSelector((state) => state.containers.main.user_api_hash)

    // console.log('user', user)
    // console.log('user_api_hash', user_api_hash)
    // console.log('userV1', userV1)
    // const userStages = user && user.stages && user.stages[0];
    
    // const stage = userStages || "UNKNOWN";
    const stage = "V1";

    const uid = currentUser || user;
    const api_hash = user_api_hash;

    const dispatch = useDispatch();

    const redux_get_user_v1 = useCallback((uid) => dispatch(v1_user.user_sign_in_request(uid)), [dispatch]);
    const redux_get_user_api_hash_v1 = useCallback((api_hash) => dispatch(v1_user_api_hash.get_user_api_hash_request(api_hash)), [dispatch]);

    useEffect(() => {
        switch (stage) {
            case "V1": {
                redux_get_user_v1(uid);
                redux_get_user_api_hash_v1(api_hash)
                break;
            }
            default: {
                break;
            }
        }

        return () => {};

    }, [dispatch, currentUser, uid, userV1.uid, user_api_hash, user_api_hashV1.api_hash, stage, redux_get_user_v1, redux_get_user_api_hash_v1]);
    // }, [dispatch, currentUser, uid, userV1.uid, stage]);

    const renderContainer = () => {
        if (!loadingAuthState && currentUser) {
            switch (stage) {
                case "V1": {
                    return <AppV1 />;
                }
                default: {
                    return <></>;
                }
            }
        } else {
            return <></>;
        }
    };



    return (
        <>
            { renderContainer() }

            <Outlet />
        </>
    )
}

export default React.memo(SSOPanel);