// import * as API_CONSTANT from "../constants";

// export const getDeviceList = () =>
//   fetch(
//     `${API_CONSTANT.slimiot_live_url}/${API_CONSTANT.account_id}/get_devices?${API_CONSTANT.query_params}`,
//     {
//       method: "GET",
//       headers: API_CONSTANT.headers,
//       // body: JSON.stringify(
//       //     items
//       // ),
//     }
//   ).then((res) => res.json());

// export const getDeviceLatest = () =>
//   fetch(
//     `${API_CONSTANT.slimiot_mock_url}/${API_CONSTANT.account_id}/get_devices_latest?${API_CONSTANT.query_params}`,
//     {
//       method: "GET",
//       headers: API_CONSTANT.headers,
//       // body: JSON.stringify(
//       //     dvid
//       // ),
//     }
//   ).then((res) => res.json());
// //getDeviceStateDate
// //getDeviceThruVehicle
// //getVehicleStateDate

import * as API_CONSTANT from "../constants";

export const getDeviceList = (user_api_hash) =>
    fetch(`https://tracking.slim-iot.com/api/get_devices?lang=en&user_api_hash=${user_api_hash}`, {
        method: "GET",
        headers: API_CONSTANT.headers,
        // mode: "cors"
        // body: JSON.stringify(
        //     items
        // ),
        mode: "cors",
    })
    .then((res) => res.json());

// export const getDeviceLatest = () => 
//     fetch(`${API_CONSTANT.slimiot_live_url}/get_devices_latest?${API_CONSTANT.query_params}`, {
//         method: "GET",
//         headers: API_CONSTANT.headers,
//         // body: JSON.stringify(
//         //     dvid
//         // ),
//     })
//     .then((res) => res.json());

    export const getDeviceLatest = () => 
    fetch(`${API_CONSTANT.slimiot_live_url}/get_devices?lang=en&user_api_hash=$2y$10$QnHw347NeZNmj24aOCERy.Zo9/06g88FdB44IOYfT9h7iyovR2MtO`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*"
        },
        mode: "cors"
    })
    .then((res) => res.json());
//getDeviceStateDate
//getDeviceThruVehicle
//getVehicleStateDate
