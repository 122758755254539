import * as API_CONSTANT from "../constants";

export const getInfoByUser = (uid, DLT = 0) =>
    fetch(`${API_CONSTANT.fms_v1_fe_path}/user/getInfo`, {
        method: "POST",
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            uid,
            DLT,
        }),
    }).then((res) => res.json());

// export const getUserAPIHash = (email, password) =>
//     fetch(`${API_CONSTANT.slimiot_live_url}/login?email=${email}&password=${password}`, {
//         method: "POST",
//         headers: API_CONSTANT.headers,
//         // body: JSON.stringify({
//         //     email,
//         //     password,
//         // }),
//     }).then((res) => res.json());

export const getUserAPIHash = (email, password) =>
    fetch(`${API_CONSTANT.slimiot_live_url}/login?email=${email}&password=${password}`, {
      method: "POST",
      headers: API_CONSTANT.headers,
      mode: "cors",
    }).then((res) => res.json());