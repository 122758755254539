import * as ActionTypes from "../action-types";

const user_DEFAULT_STATE = {};

export const user = (state = user_DEFAULT_STATE, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.SSO_USER_SIGN_IN: {
      // console.log("action", action)
      const user = action.user;
      newState = user;
      return newState;
    }
    case ActionTypes.SSO_USER_SIGN_OUT: {
      return user_DEFAULT_STATE;
    }
    case ActionTypes.SET_USER_EMAIL_DATA: {
      const email = action.email;
      newState.email = email;
      return newState;
    }
    case ActionTypes.AUTH_USER_SIGN_UP: {
      const authKey = action.authKey;
      newState = {
        ...newState,
        authKey: authKey,
      };
      return newState;
    }
    case ActionTypes.SSO_USER_SIGN_UP: {
      newState = {
        ...newState,
      };
      return newState;
    }
    case ActionTypes.SSO_USER_RESET_PASSWORD_REQUEST: {
      const link = action.link;
      newState.link = link;
      return newState;
    }
    default: {
      return newState;
    }
  }
};
