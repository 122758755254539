import { combineReducers } from "redux";

/**
 * Root Redux Reducer
 */
import { auth } from "./auth";

/**
 * Containized Redux Reducer
 */
import { mainReducer } from "../../../containers/main/services/redux/reducers";
import { v1Reducer } from "@src/containers/v1/services/redux/reducer";

// Main Container Redux Action Types
import * as MAIN_ActionTypes from "../../../containers/main/services/redux/action-types";

const appReducer = () =>
  combineReducers({
    containers: combineReducers({
      main: mainReducer,
      v1: v1Reducer
    }),
    root: combineReducers({
      auth,
    }),
    // ... // rest of your reducers
  });

export const rootReducer = (history) => (state, action) => {
  if (action && action.type === MAIN_ActionTypes.SSO_USER_SIGN_OUT) {
    return appReducer(history)(undefined, action);
  }

  return appReducer(history)(state, action);
};
