import * as ActionTypes from "../action-types";

export const setLoadingGeneral = () => {
  return {
    type: ActionTypes.SET_LOADING_GENERAL,
  };
};

export const unsetLoadingGeneral = () => {
  return {
    type: ActionTypes.UNSET_LOADING_GENERAL,
  };
};

export const setLoadingUser = () => {
  return {
    type: ActionTypes.SET_LOADING_USER,
  };
};

export const unsetLoadingUser = () => {
  return {
    type: ActionTypes.UNSET_LOADING_USER,
  };
};

export const setLoadingDeviceList = () => {
    return {
        type: ActionTypes.SET_LOADING_DEVICE_LIST,
    };
};
  
export const unsetLoadingDeviceList = () => {
    return {
        type: ActionTypes.UNSET_LOADING_DEVICE_LIST,
    };
};

export const setLoadingDeviceLatest = () => {
  return {
    type: ActionTypes.SET_LOADING_DEVICE_LATEST,
  };
};

export const unsetLoadingDeviceLatest = () => {
  return {
    type: ActionTypes.UNSET_LOADING_DEVICE_LATEST,
  };
};

export const setLoadingBookingList = () => {
  return {
      type: ActionTypes.SET_LOADING_BOOKING_LIST,
  };
};

export const unsetLoadingBookingList = () => {
  return {
      type: ActionTypes.UNSET_LOADING_BOOKING_LIST,
  };
};

export const setLoadingBooking = () => {
  return {
      type: ActionTypes.SET_LOADING_BOOKING,
  };
};

export const unsetLoadingBooking = () => {
  return {
      type: ActionTypes.UNSET_LOADING_BOOKING,
  };
};
