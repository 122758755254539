// Constants Library
import { FEATURE_NAME } from "../common/features";

export const ROUTE_URL = {
    [FEATURE_NAME.DASHBOARD]: "/",
    [FEATURE_NAME.VEHICLE_TRACKING]: '/tracking/vehicle',
};

export const ROUTE_TITLE = {
    [FEATURE_NAME.DASHBOARD]: 'Vehicle Tracking',
    [FEATURE_NAME.VEHICLE_TRACKING]: 'Vehicle Tracking',
};

export const ROUTE_TITLE_PATH = Object.fromEntries(
    Object.entries(ROUTE_URL).map(([key, value]) => [value, ROUTE_TITLE[key]])
);
