import { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Select } from "antd";

import { TEMPLATE } from "../../../../constants";

import styles from "../common.module.css";

const { MODALS } = TEMPLATE.MODAL;

const ModalStandard = (props) => {
  const {
    setmodal,
    isModalOpen,
    setIsModalOpen,
    onClick = () => {},
    extra,
    actiontype,
  } = props;

  const handleOkButton = () => {
    onClick();
    setIsModalOpen();
  };

  const content =
    (extra && Object.keys(extra).length && MODALS[setmodal].content(extra)) ||
    MODALS[setmodal].content;

  const contentArray = Array.isArray(content) ? content : [content];

  const renderedContent = contentArray.map((line, index) => (
    <p key={index} className={styles.textCard}>
      {line}
    </p>
  ));

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      onCancel={setIsModalOpen}
      width={400}
    >
      <div className={styles["content-container"]}>
        <img src={MODALS[setmodal].img} alt="the logo" />

        {/* <div className={styles["contents"]}> */}
        <h3 className={styles["title"]}>{MODALS[setmodal].title}</h3>
        <div>{renderedContent}</div>

        <div className={styles["buttons"]}>
          <button
            onClick={handleOkButton}
            className={`${styles["button"]} ${styles["active"]}`}
            style={{ borderRadius: "5px" }}
          >
            Yes, proceed
          </button>
          <button
            onClick={setIsModalOpen}
            className={styles["button"]}
            style={{ borderRadius: "5px" }}
          >
            No, cancel
          </button>
        </div>
        {/* </div> */}
      </div>
    </Modal>
  );
};

export default ModalStandard;
