// React Third Party Library
import React, { useState, useEffect } from "react";
import { InfoWindow } from "@react-google-maps/api";
import { PoweroffOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { RequestType, geocode } from "react-geocode";

const VehicleInfoWindow = (props) => {
  const { selectedMarker, onClose, address, devices, booking } = props;
  const [updatedMarkerData, setUpdatedMarkerData] = useState(selectedMarker);
  const [updatedAddress, setUpdatedAddress] = useState(address);
  const [driver, setDriver] = useState("");
  const [phoneMy, setPhoneMy] = useState("");
  const [phoneSg, setPhoneSg] = useState("");

  useEffect(() => {
    if (selectedMarker && selectedMarker.id) {
      const updatedData = devices.find((data) => data.id === selectedMarker.id);
      if (updatedData) {
        setUpdatedMarkerData(updatedData);

        // Update address
        geocode(RequestType.LATLNG, `${updatedData.lat},${updatedData.lng}`)
          .then(({ results }) => {
            const address = results[0].formatted_address;
            setUpdatedAddress(address);
          })
          .catch(console.error);

        // Match data from booking
        const splitName = updatedData.name.replace(/\s/g, "").split("-");
        const registrationNo = splitName[1];
        const matchingData = booking.find(
          (d) =>
            d.Extension.RegistrationNumber.replace(/\s/g, "") === registrationNo
        );

        if (matchingData) {
          setDriver(matchingData.Extension.Driver);
          setPhoneMy(matchingData.Extension.DriverPhoneNumberMY);
          setPhoneSg(matchingData.Extension.DriverPhoneNumberSG);
        }
      }
    }
  }, [selectedMarker, devices, booking]);

  const tabsItems = [
    {
      key: "1",
      label: "Location",
      children: (
        <div>
          <p>{updatedAddress}</p>
          <p>Stop duration: {updatedMarkerData.stop_duration}</p>
          <p>Last updated at {updatedMarkerData.time}</p>
        </div>
      ),
    },
    {
      key: "2",
      label: "View More",
      children: (
        <div>
          <p>Driver: {driver}</p>
          <p>
            Phone Number: {phoneMy || "-"} (MY) , {phoneSg || "-"} (SG)
          </p>
          <p>
            Position: {updatedMarkerData.lat}, {updatedMarkerData.lng}
          </p>
          <p>Altitude: {updatedMarkerData.altitude} mt</p>
          <p>Angle: {updatedMarkerData.course}&#176;</p>
        </div>
      ),
    },
  ];

  return (
    <>
      <InfoWindow
        key={updatedMarkerData.id}
        position={{ lat: updatedMarkerData.lat, lng: updatedMarkerData.lng }}
        onCloseClick={onClose}
        options={{
          pixelOffset: new window.google.maps.Size(0, -8), // move up in pixel by increasing the negative value (larger negative move it higher)
        }}
      >
        <div style={{ width: "350px", fontFamily: "Calibri", fontWeight: 500 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {/* <br /> */}
              <h4 style={{ fontWeight: "bold" }}>{updatedMarkerData.name}</h4>
              <p>{updatedMarkerData.speed} km/h</p>
            </div>
            <PoweroffOutlined
              style={{
                fontSize: "20px",
                color:
                  updatedMarkerData.sensors[0].value === "On"
                    ? "green"
                    : "grey",
              }}
            />
          </div>
          <Tabs
            defaultActiveKey="1"
            items={tabsItems}
            style={{ fontFamily: "Calibri" }}
          />
        </div>
      </InfoWindow>
    </>
  );
};

export default VehicleInfoWindow;
