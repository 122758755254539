import * as API from "../../api";
import { message } from "antd";
import { TIME } from "../../../services/util";

import * as ActionTypes from "../action-types";
import {
  setLoadingBooking,
  setLoadingBookingList,
  unsetLoadingBooking,
  unsetLoadingBookingList,
} from "./style";
const { dayJSMilliSecond, currentTimeMilliSecond } = TIME;

export const get_booking_list_request = (items) => (dispatch) => {
  dispatch(setLoadingBookingList());

  API.getBookingList(items)
    .then((info) => {
      const { status, msg, booking = info.result?.list } = info;
      // console.log("booking in actions", booking);

      // const filteredBooking = booking.filter((booking) => {
      //   const bookingStartTime = dayJSMilliSecond(booking.Extension.StartTime);
      //   const bookingEndTime = bookingStartTime + 3 * 60 * 60 * 1000; // 3 hours in milliseconds
      //   const currentDateTime = currentTimeMilliSecond();

      //   //   return (
      //   //     bookingStartTime <= currentDateTime &&
      //   //     bookingEndTime >= currentDateTime
      //   //   );
      //   // });

      //   // Check if the booking is still ongoing or has not started yet for the day
      //   if (
      //     bookingEndTime >= currentDateTime ||
      //     bookingStartTime > currentDateTime
      //   ) {
      //     return true;
      //   }
      //   return false;
      // });
      // console.log("filteredBooking", filteredBooking);

      dispatch(get_booking_list_success(booking));
    })
    .finally(() => {
      dispatch(unsetLoadingBookingList());
    });
};

const get_booking_list_success = (booking) => {
  return {
    type: ActionTypes.GET_ALL_BOOKING_LIST,
    booking,
  };
};

export const loading_booking_request = (items) => (dispatch) => {
  dispatch(setLoadingBooking());

  API.getBookingList(items)
    .then((info) => {
      const { status, msg, booking = info.result?.list } = info;

      dispatch(loading_booking_success(booking));
    })
    .finally(() => {
      dispatch(unsetLoadingBooking());
    });
};

const loading_booking_success = (booking) => {
  return {
    type: ActionTypes.LOADING_BOOKING,
    booking,
  };
};
