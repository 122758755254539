import * as API from "../../api";
import { message } from "antd";

import * as ActionTypes from "../action-types";
import { setLoadingBookingList, unsetLoadingBookingList } from "./styles";

export const get_booking_list_request = (items) => (dispatch) => {
  dispatch(setLoadingBookingList());

  API.getBookingList(items)
    .then((info) => {
      const { status, msg, booking = info.result.list } = info;
      // console.log("booking in actions", booking);

      //   if (status === 200) {
      dispatch(get_booking_list_success(booking));
      //     message.success("Get Booking List Data Successfully");
      //   } else {
      //     message.error(msg);
      //   }
    })
    .finally(() => {
      dispatch(unsetLoadingBookingList());
    });
};

const get_booking_list_success = (booking) => {
  return {
    type: ActionTypes.GET_ALL_BOOKING_LIST,
    booking,
  };
};
