import * as ActionTypes from "../action-types";

const STYLES_INITIAL_STATE = {
  loading: {
    general: false,
    user: false,
    device: false,
    booking: false,
    loadingBooking: false,
  },
};

export const style = (state = STYLES_INITIAL_STATE, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.SET_LOADING_GENERAL: {
      newState.loading.general = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_GENERAL: {
      newState.loading.general = false;
      return newState;
    }
    // case ActionTypes.SET_LOADING_USER: {
    //   newState.loading.user = true;
    //   return newState;
    // }

    // case ActionTypes.UNSET_LOADING_USER: {
    //   newState.loading.user = false;
    //   return newState;
    // }

    case ActionTypes.SET_LOADING_DEVICE_LIST: {
      newState.loading.deviceList = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_DEVICE_LIST: {
      newState.loading.deviceList = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_DEVICE_LATEST: {
      newState.loading.deviceLatest = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_DEVICE_LATEST: {
      newState.loading.deviceLatest = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_BOOKING_LIST: {
      newState.loading.booking = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_BOOKING_LIST: {
      newState.loading.booking = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_BOOKING: {
      newState.loading.loadingBooking = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_BOOKING: {
      newState.loading.loadingBooking = false;
      return newState;
    }
    default: {
      return state;
    }
  }
};
