// React Third Party Library
import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  MarkerClusterer,
  OverlayView,
} from "@react-google-maps/api";
import { Badge, Card, Input, Layout, Row, Empty, Spin } from "antd";
import { RequestType, geocode, setDefaults } from "react-geocode";

// Components
import { VehicleInfoWindow } from "./components";

// Constants
import { REDUX as REDUX_UTIL } from "../../services/util";
import * as REDUX_ACTION from "../../services/redux/actions";

// Handler Library
import { APP_CONFIG } from "../../config";

// Service Library
import { TIME } from "../../services/util";

// Styles Library
import "../../pages/index.css";

const { Sider, Content } = Layout;
const { reduxStoreWrapper } = REDUX_UTIL;
const {
  referenceStartTime,
  referenceEndOfDay,
  dayJSMilliSecond,
  currentTimeMilliSecond,
} = TIME;

const VehicleTracking = () => {
  const dispatch = useDispatch();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [infoWindowVisible, setInfoWindowVisible] = useState(false);
  const [mapRef, setMapRef] = useState();
  const [address, setAddress] = useState("");

  // const user = useSelector((state) => state.containers.main.user);
  const company = useSelector((state) => state.containers.main.company);
  const user_api_hash = useSelector(reduxStoreWrapper("user_api_hash"));
  const user = "$2y$10$sfICT5sOHzI94xbExwOo6eSETgQA/3OZGnqXwnDR.Scl5lUTRsz46";
  const loading = useSelector(reduxStoreWrapper("style.loading.loadingBooking"));

  const data = {
    moduleId: 3498,
    logic: "GPS-IOT-Integration-Jobs",
    payload: {
      // startDate: referenceStartTime().format("YYYY-MM-DD"),
      // endDate: referenceEndOfDay().format("YYYY-MM-DD"),
      // company: company.company,
      company: "DYSON",
      partialMatch: true,
    },
  };

  const bookingList = Object.values(
    useSelector(reduxStoreWrapper("loadingBooking.byID"))
  ).map((d) => {
    return {
      key: d.DocumentId,
      ...d,
    };
  });

  const booking = Object.values(
    useSelector(reduxStoreWrapper("booking.byID"))
  ).map((d) => {
    return {
      key: d.DocumentId,
      ...d,
    };
  });

  const devices = Object.values(
    useSelector(reduxStoreWrapper("device.byID"))
  ).map((d) => {
    return {
      key: d.id,
      ...d,
    };
  });

  useEffect(() => {
    dispatch(REDUX_ACTION.v1_user_api_hash.get_user_api_hash_request());
    dispatch(REDUX_ACTION.v1_booking.loading_booking_request(data));
  }, [dispatch]);

  const fetchData = () => {
    dispatch(REDUX_ACTION.v1_device.get_all_device_list_request(user));
    dispatch(REDUX_ACTION.v1_booking.get_booking_list_request(data));
  };

  useEffect(() => {
    fetchData(); // Fetch data on initial load

    const interval = setInterval(() => {
      fetchData(); // Fetch data at regular intervals
    }, 30000); // 30 seconds interval

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [dispatch, user]);

  const filterExpiredBookings = (booking) => {
    const currentDateTime = currentTimeMilliSecond();
    return booking.filter((booking) => {
      const bookingStartTime = dayJSMilliSecond(booking.Extension.StartTime);
      const bookingEndTime = bookingStartTime + 3 * 60 * 60 * 1000; // 3 hours in milliseconds
      return (
        bookingEndTime >= currentDateTime || bookingStartTime > currentDateTime
      );
    });
  };

  const filteredBooking = filterExpiredBookings(booking);

  const matchDevices = devices.filter((device) => {
    const splitName = device.name.replace(/\s/g, "").split("-"); // remove whitespace then split
    const registrationNo = splitName[1];

    const matchingData = booking.find(
      (b) =>
        b.Extension.RegistrationNumber.replace(/\s/g, "") === registrationNo
    );

    return matchingData;
  });

  useEffect(() => {
    if (mapRef && matchDevices.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      matchDevices.forEach((d) => {
        bounds.extend({ lat: d.lat, lng: d.lng });
      });

      if (!selectedMarker) {
        // If no marker is selected, fit bounds for all markers
        mapRef.fitBounds(bounds);
      } else {
        // If a marker is selected, pan to selected marker and zoom in
        const selectedDevice = matchDevices.find(
          (d) => d.id === selectedMarker.id
        );
        if (selectedDevice) {
          // Get the current position of the selected device
          mapRef?.panTo({ lat: selectedDevice.lat, lng: selectedDevice.lng });
          mapRef?.setZoom(16);
        }
      }
    }
  }, [matchDevices, mapRef, selectedMarker]);

  // useEffect(() => {
  //   if (mapRef && matchDevices.length > 0) {
  //     const bounds = new window.google.maps.LatLngBounds();
  //     matchDevices.forEach((d) => {
  //       bounds.extend({ lat: d.lat, lng: d.lng });
  //     });
  //     mapRef.fitBounds(bounds);

  //     if (selectedMarker) {
  //       mapRef?.panTo({ lat: selectedMarker.lat, lng: selectedMarker.lng });
  //       mapRef?.setZoom(16);
  //     }
  //   }
  // }, [matchDevices, mapRef, selectedMarker]);

  const filteredData = Object.values(matchDevices).filter((item) =>
    item.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setInfoWindowVisible(true);
    getDeviceAddress(marker);
  };

  setDefaults({
    key: APP_CONFIG.APP_GOOGLE_MAP_API_KEY,
    language: "en",
    region: "es",
  });

  const getDeviceAddress = (device) => {
    geocode(RequestType.LATLNG, `${device.lat},${device.lng}`)
      .then(({ results }) => {
        const address = results[0].formatted_address;
        setAddress(address);
        return address;
      })
      .catch(console.error);
  };

  const mapContainerStyle = {
    width: "100%",
    height: "100vh",
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: APP_CONFIG.APP_GOOGLE_MAP_API_KEY,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const onLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    matchDevices.forEach((d) => {
      bounds.extend({ lat: d.lat, lng: d.lng });
    });
    map.fitBounds(bounds);
    setMapRef(map);
  };

  const onUnmount = () => {
    setInfoWindowVisible(false);
  };

  const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
    return {
      x: -(offsetWidth / 2), // center it above the marker
      y: -(offsetHeight - 10), // adjust to be above the marker (10 pixel above marker)
      // x: offsetWidth / 2,
      // y: -offsetHeight,
    };
  };

  return (
    <div
      className="container"
      style={{
        width: "100%",
        margin: "0em auto",
      }}
    >
      <Layout
        style={{
          height: "100%",
        }}
      >
        <Sider
          width={340}
          collapsed={false}
          style={{ backgroundColor: "#FFFFFF", overflowY: "scroll" }}
        >
          <Row
            style={{
              width: "100%",
              height: "100px",
              borderBottom: "1px solid #E5E5E5",
            }}
          >
            <Input
              placeholder="Search a vehicle"
              style={{
                width: "75%",
                margin: "30px auto 0",
                fontFamily: "Calibri",
                height: "fit-content",
              }}
              onChange={handleSearchInputChange}
              value={searchInput}
            />
          </Row>
          {loading ? (
            <Spin style={{ margin: "20px auto", display: "block" }} />
          ) : filteredData.length === 0 ? (
            <Empty
              style={{
                fontFamily: "Calibri",
                margin: "20px auto 0",
              }}
              description="No vehicles data found"
            />
          ) : (
            filteredData.map((item, index) => {
              const matchingBooking = booking.filter(
                (b) =>
                  b.Extension.RegistrationNumber.replace(/\s/g, "") ===
                  item.name.replace(/\s/g, "").split("-")[1]
              );

              return (
                <div key={item.id}>
                  {matchingBooking.map((matchingBooking) => (
                    <Card
                      key={matchingBooking.DocumentId}
                      style={{
                        fontFamily: "Calibri",
                        width: "80%",
                        margin: "20px auto 0",
                        cursor: "pointer",
                        fontWeight: 500,
                      }}
                      bodyStyle={{ padding: 12 }}
                      position={{ lat: item.lat, lng: item.lng }}
                      onClick={() => handleMarkerClick(item)}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Badge
                          color={
                            item.online === "online" || item.online === "moving"
                              ? "green"
                              : item.online === "offline"
                              ? "red"
                              : "yellow"
                          }
                          text={item.name}
                          style={{ fontFamily: "Calibri", fontWeight: "bold" }}
                        />
                        <p>{item.speed} km/h</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderTop: "1px dashed grey",
                          paddingTop: "10px",
                          marginTop: "-5px",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p style={{ color: "grey", margin: "0" }}>
                            Passenger
                          </p>
                          <p>{matchingBooking.Extension.CustomerName || "-"}</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <p
                            style={{
                              color: "grey",
                              margin: "0",
                              textWrap: "nowrap",
                            }}
                          >
                            TC Number
                          </p>
                          <p>{matchingBooking.Extension.TCNumber || "-"}</p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{item.time}</p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <p style={{ color: "grey", margin: "0" }}>
                            Travel Ticket
                          </p>
                          <p style={{ margin: "0" }}>
                            {matchingBooking.Extension.TravelTickets || "-"}
                          </p>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              );
            })
          )}
        </Sider>
        {isLoaded && (
          <GoogleMap
            onClick={() => setInfoWindowVisible(false)}
            mapContainerStyle={mapContainerStyle}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {matchDevices.map((item, index) => (
              <div>
                <Marker
                  key={item.id}
                  position={{ lat: item.lat, lng: item.lng }}
                  onClick={() => handleMarkerClick(item)}
                  icon={{
                    fillOpacity: 1,
                    fillColor: "#FD6A0A",
                    scale: 5,
                    strokeWeight: 2,
                    strokeColor: "#FD6A0A",
                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    rotation: item.course || 0,
                  }}
                />
                <OverlayView
                  key={index}
                  position={{ lat: item.lat, lng: item.lng }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={(x, y) =>
                    getPixelPositionOffset(x, y, { x: -30, y: -15 })
                  }
                >
                  <b
                    style={{
                      background: "#FFFFFF",
                      padding: "6px 9px",
                      borderRadius: "4px",
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      borderStyle: "solid",
                      borderColor: "#808080",
                      borderWidth: "1px",
                      fontFamily: "Calibri",
                    }}
                  >
                    {item.name}
                  </b>
                </OverlayView>
              </div>
            ))}
            {infoWindowVisible && (
              <VehicleInfoWindow
                selectedMarker={selectedMarker}
                onClose={() => setInfoWindowVisible(false)}
                address={address}
                devices={devices}
                booking={booking}
              />
            )}
          </GoogleMap>
        )}
      </Layout>
    </div>
  );
};

export default VehicleTracking;
