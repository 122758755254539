export const SET_LOADING_GENERAL = "MAIN_SET_LOADING_GENERAL";
export const UNSET_LOADING_GENERAL = "MAIN_UNSET_LOADING_GENERAL";

export const SET_LOADING_AUTH = "MAIN_SET_LOADING_AUTH";
export const UNSET_LOADING_AUTH = "MAIN_UNSET_LOADING_AUTH";

export const SET_LOADING_AUTH_SIGNUP = "MAIN_SET_LOADING_AUTH_SIGN_UP";
export const UNSET_LOADING_AUTH_SIGNUP = "MAIN_UNSET_LOADING_AUTH_SIGNUP";

export const SET_LOADING_USER_API_HASH = "MAIN_SET_LOADING_USER_API_HASH";
export const UNSET_LOADING_USER_API_HASH = "MAIN_UNSET_LOADING_USER_API_HASH";

export const SET_LOADING_BOOKING_LIST = "MAIN_SET_LOADING_BOOKING_LIST";
export const UNSET_LOADING_BOOKING_LIST = "MAIN_UNSET_LOADING_BOOKING_LIST";
