import React from "react";
import { Routes, Route } from "react-router";

// Constant
import { ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components
import { asyncComponent } from "../../../../components";
import { ROOT_PAGES } from "../../../../pages";

// Modules
import VehicleTracking from "../../../../pages/VehicleTracking";

const AsyncNotFoundPage = asyncComponent(() => <ROOT_PAGES.NotFoundPage />);

const { ROUTE_URL } = ROUTES;

const MainRoutes = () => {
    // const location = useLocation();

    // const { state = {} } = location;

    return (
        <Routes>
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.DASHBOARD]}
                element={<VehicleTracking />}
            />
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.VEHICLE_TRACKING]}
                element={<VehicleTracking />}
            />
            <Route element={<AsyncNotFoundPage />} />
        </Routes>
    );
};
export default MainRoutes;
