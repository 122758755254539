// import React from "react";
import {
  DownloadOutlined,
  LogoutOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

// Constants
import { FEATURE_NAME, FEATURE_TYPE } from "../common/features";

// Services

export const FEATURES = FEATURE_NAME;

export const FEATURE_LABEL = {
  // Managements
  // [FEATURE_NAME.VEHICLE_TRACKING]: "Vehicle Tracking",
  [FEATURE_NAME.LOGOUT]: "Logout",
};

// const MENU_ICON_SIZE = 24;

const MENU_COMPONENT_ITEM = (menu) => ({
  key: menu.feature,
  // icon: (menu.icon && <menu.icon style={{ fontSize: MENU_ICON_SIZE }} />) || null,
  icon: menu.icon || null,
  label: FEATURE_LABEL[menu.feature],
  feature: menu.feature,
  type: menu.type || null,
  children:
    (menu.children && menu.children.map((m) => MENU_COMPONENT_ITEM(m))) || [],
  disabled: menu.disabled,
  function: menu.function,
});

export const FEATURE_MENU_ITEMS_OBJ = {
  // First Layer
  // [FEATURE_NAME.VEHICLE_TRACKING]: {
  //     feature: FEATURE_NAME.VEHICLE_TRACKING ,
  //     icon: <EnvironmentOutlined />,
  //     routeType: FEATURE_TYPE.ROUTE,
  //     disabled: false,
  // },

  // Second Layer
  [FEATURE_NAME.ACCOUNT_PROFILE]: {
    feature: FEATURE_NAME.ACCOUNT_PROFILE,
    // icon: SIDEBAR.icon_my_account,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.LOGOUT]: {
    feature: FEATURE_NAME.LOGOUT,
    icon: <LogoutOutlined />,
    routeType: FEATURE_TYPE.LOGOUT,
    disabled: false,
  },
};

export const FEATURE_MENU_ITEMS = Object.values(FEATURE_MENU_ITEMS_OBJ);

export const FEATURE_MENU_ITEMS_LAYER_FIRST = [
  // FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.VEHICLE_TRACKING],
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.LOGOUT],
];

// export const FEATURE_MENU_ITEMS_LAYER_SECOND = [
//     {
//         type: "group",
//         children: [
//             FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.ACCOUNT_PROFILE],
//             FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.LOGOUT],
//         ],
//     },
// ];

export const FEATURE_MENU_ITEMS_COMP = FEATURE_MENU_ITEMS.map((menu) =>
  MENU_COMPONENT_ITEM(menu)
);

export const FEATURE_MENU_ITEMS_FIRST_LAYER_GROUP =
  FEATURE_MENU_ITEMS_LAYER_FIRST.map((menu) => MENU_COMPONENT_ITEM(menu));

// export const FEATURE_MENU_ITEMS_SECOND_LAYER_GROUP =
//     FEATURE_MENU_ITEMS_LAYER_SECOND.map((menu) => MENU_COMPONENT_ITEM(menu));
