import { useState } from "react";
import { Button, Tag } from "antd";

import { TEMPLATE } from "../../constants";

import { ButtonLink } from "../../components";

import { ModalStandard } from "./modules";

const { MODALS_STANDARD } = TEMPLATE.MODAL;

// the commented code below are the example of how to use the modal

// dont forget to import the TEMPLATE, this TEMPLATE available inside v1

// beside being the reusable component
// the ButtonModal is a button without any styling
// so the styling itself can be customized

const ButtonModal = (props) => {
  const {
    children,
    setmodal,
    onClick = () => {},
    extra = {},
    model = "",
    disabled,
    actiontype,
    isOpen,
    setIsOpen,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  // filter the MODALS based on setmodal value
  const modals_standard = MODALS_STANDARD.filter((k) => k === setmodal)[0];

  const handleModal = () => {
    setIsModalOpen(true);
  };

  const handleModalContext = (setmodal) => {
    switch (setmodal) {
      case modals_standard:
        return (
          <ModalStandard
            setmodal={setmodal}
            isModalOpen={isOpen !== undefined ? isOpen : isModalOpen}
            setIsModalOpen={
              isOpen !== undefined ? setIsOpen : () => setIsModalOpen(false)
            }
            onClick={onClick}
            extra={extra}
            actiontype={actiontype}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      {handleModalContext(setmodal)}

      {(model === "link" && (
        <ButtonLink {...props} onClick={handleModal} disabled={disabled}>
          {children}
        </ButtonLink>
      )) ||
        (model === "instant" && children) || (
          <Button {...props} onClick={handleModal}>
            {children}
          </Button>
        )}
    </>
  );
};
export default ButtonModal;
