import * as API_CONSTANT from '../constants'

export const getUserInfoByAuthID = (authid, authProvider) => (
  fetch(`${API_CONSTANT.fms_sso_path}/user/authid/getInfo`, {
      method: 'POST',
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
          authid, 
          authProvider
      })
  })
  .then(res => res.json())
)

// export const getUserAPIHash = (email, password) =>
//   fetch(`${API_CONSTANT.slimiot_live_url}/login?email=${email}&password=${password}`, {
//       method: "POST",
//       headers: API_CONSTANT.headers,
//     }).then((res) => res.json());

export const getUserAPIHash = (email, password) =>
  fetch(`https://tracking.slim-iot.com/api/login?email=${email}&password=${password}`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    mode: "cors",
  }).then((res) => res.json());

export const getUserInfoByAPIHash = (userAPIHash) => (
  fetch(`${API_CONSTANT.slimiot_live_url}/userinfo?user_api_hash=${userAPIHash}`, {
      method: 'POST',
      headers: API_CONSTANT.headers,
    }).then(res => res.json()));