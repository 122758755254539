import { combineReducers } from "redux";

/**
 * Main reducers
 */
import { user } from './user'
import { style } from './styles'
import { user_api_hash } from './user_api_hash'
import { company } from "./company";
import { booking } from "./booking";

export const mainReducer = combineReducers({
  user,
  style,
  user_api_hash,
  company,
  booking,
});
