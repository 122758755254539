// Services
import { FIREBASE } from '@src/services/cloud'
import * as CONFIG from '@src/config'
import { 
    signInWithEmailAndPassword,
    signOut
} from 'firebase/auth'

export const sign_in = async(email, password) =>
    signInWithEmailAndPassword(FIREBASE.auth, email, password)
    .then(result => {     

        return {
            status: 200,
            data: {
                currentUser: result.user.uid,
                authProvider: CONFIG.APP_CONFIG.AUTHENTICATION_PROVIDER
            }
        }
      })
    .catch(err => {
        return {
            status: 500,
            msg: err.message
        }
    });

export const sign_out = async() => 
    signOut(FIREBASE.auth)