export const FEATURE_NAME = {

    DASHBOARD: 'DASHBOARD',

    VEHICLE_TRACKING: 'VEHICLE_TRACKING',

    // Second Layer
    ACCOUNT_PROFILE: 'ACCOUNT_PROFILE',
    LOGOUT: 'LOGOUT'

};
  
export const FEATURE_TYPE = {
    ROUTE: "ROUTE",
    LOGOUT: "LOGOUT",
};
  