import React from "react";

// Components
import Layout from "../v1/layout";
import MainRoutes from "../v1/services/navigation/route/main";

const App = (props) => {

    return (
        <>
            <Layout>
                <MainRoutes {...props} />
            </Layout>
        </>
    );
};

export default React.memo(App);
