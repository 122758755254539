import * as ActionTypes from "../action-types";

const user_api_hash_defaultState = {
  byID: {},
};

export const user_api_hash = (state = user_api_hash_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.V1_GET_USER_API_HASH: {
      const user_api_hash = action.user_api_hash;
      newState = user_api_hash;
      return newState;
    }
    default: {
      return state;
    }
  }
};
