// React Thirty Party Library
import {
    Layout as LayoutAntd,
    Button,
} from "antd";
import { useLocation } from "react-router";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined
} from "@ant-design/icons";

// Constants Library
import { ROUTES } from "../../../constants";

// Components Library

// Handler Library

// Styles Library
import "../../index.css";

const { Header } = LayoutAntd;

const { ROUTE_TITLE_PATH } = ROUTES;

const LayoutHeader = ({
    collapsed,
    setCollapsed
}) => {

    const path = useLocation();
    const title = ROUTE_TITLE_PATH[path.pathname] || "";


    return (
        <Header
            className="sidebar-header-container"
            style={{
                width: collapsed ? "calc(100% - 80px)" : "calc(100% - 28vh)",
                transition: "width 0.5s",
                backgroundColor: '#fff',
            }}
        >
            <div className="sidebar-header-title">
                <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                        fontSize: "16px",
                        width: 64,
                        height: 64,
                    }}
                />
                <h3 style={{ fontSize: "16px", fontWeight: "bold" }}>{title}</h3>
            </div>
        </Header>
    );
};

export default LayoutHeader;
