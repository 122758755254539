// Third Party Library
import * as API from "../../api";
import * as ActionTypes from "../action-types";
import { batch } from "react-redux";
import { message } from "antd";

// Constants and Config

// Services
import * as Auth from "../../auth";

// Redux Action

import { setLoadingGeneral, unsetLoadingGeneral, setLoadingUser, unsetLoadingUser } from "./style";

// Neccessary Actions
import { auth_user_sign_out } from "../../../../main/services/redux/actions/user";

// export const user_sign_in_request = (userAPIHash) => (dispatch) => {
//     dispatch(setLoadingGeneral());

//     // Promise.all([API.getInfoByUser(uid)])
//     //     .then(([infoUser]) => {

//     //         if (infoUser.errorType === "ValidationException") {

//     //             message.error("Error. Refresh page?");


//     //         } else {
//     //             const { user } = infoUser;

//     //             batch(() => {
//     //                 if (user && user.status) {

//     //                     dispatch(user_sign_in_success(user));


//     //                 } else {
//     //                     if (!user) {
//     //                         message.warning("No Verified User Found!");
//     //                     }

//     //                     if (!user.status) {
//     //                         message.error("User has been suspended by Admin. Please contact SlimIOT admin for further information");
//     //                     }
//     //                     dispatch(user_sign_out_request());
//     //                 }
//     //             });
//     //         }
//     //     })
//     //     .finally(() => {
//     //         dispatch(unsetLoadingGeneral());
//     //     });
//     dispatch(user_sign_in_success(userAPIHash));
//     dispatch(unsetLoadingGeneral());
// };

export const user_sign_in_request = (uid) => (dispatch) => {

    dispatch(user_sign_in_success({uid}));
};

const user_sign_in_success = (user) => {
    return {
        type: ActionTypes.USER_SIGN_IN,
        user,
    };
};

export const user_sign_out_request = () => (dispatch) => {

    batch(() => {
        dispatch(auth_user_sign_out());
        dispatch(user_sign_out_success());
    });
};

const user_sign_out_success = () => {
    return {
        type: ActionTypes.USER_SIGN_OUT,
    };
};

// export const user_change_password_request = (email, oldPassword, newPassword, cb = () => {}) => (dispatch) => {

//     Auth.change_password(email, oldPassword, newPassword)
//     .then((response) => {

//         const { status } = response;

//         if (status === 200) {

//             cb({ status: 200 });

//             batch(() => {
//                 dispatch(user_change_password_success());
//                 dispatch(user_sign_out_request());
//             });
//         } else {
//             cb({ status: 404 });
//         }
//     })
//     .catch((err) => {
//         cb({});
//     });
// };

// const user_change_password_success = () => {
//     return {
//         type: ActionTypes.USER_UPDATE_PASSWORD,
//     };
// };

// export const user_update_data_request = (uid, data, cb = () => {}) => (dispatch) => {

//     API.updateUserInfo(uid, data)
//     .then((info) => {

//         const { status, operator, msg } = info;

//         if (status === 200) {
//             dispatch(user_update_data_success(operator));
//         } else {
//             message.warning(msg);
//         }
//     });
// };

// const user_update_data_success = (user) => {
//     return {
//         type: ActionTypes.USER_UPDATE_DATA,
//         user,
//     };
// };