import * as ActionTypes from "../action-types";

const INITIAL_STATE = {
  loading: {
    auth: false,
    authSignup: false,
    general: false,
  },
  toggleUpdate: false,
};

export const style = (state = INITIAL_STATE, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.SET_LOADING_GENERAL: {
      newState.loading.general = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_GENERAL: {
      newState.loading.general = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_AUTH: {
      newState.loading.auth = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_AUTH: {
      newState.loading.auth = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_AUTH_SIGNUP: {
      newState.loading.authSignup = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_AUTH_SIGNUP: {
      newState.loading.authSignup = false;
      return newState;
    }

    default: {
      return state;
    }
  }
};
