import * as ActionTypes from "../action-types";

const loadingBooking_defaultState = {
  byID: {},
  allIDs: [],
};

export const loadingBooking = (state = loadingBooking_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.LOADING_BOOKING: {
      // console.log("action for booking", action);
      const booking = action.booking;

      booking &&
        booking.length &&
        booking.map((booking) => {
          const DocumentId = booking.DocumentId;

          newState.byID[DocumentId] = booking;

          if (!newState.allIDs.includes(DocumentId))
            newState.allIDs.push(DocumentId);

          return null;
        });

      return newState;
    }
    default: {
      return state;
    }
  }
};
