import * as ActionTypes from "../action-types";

const booking_defaultState = {
  byID: {},
  allIDs: [],
};

export const booking = (state = booking_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_ALL_BOOKING_LIST: {
      // console.log("action for booking", action);
      const booking = action.booking;

      booking &&
        booking.length &&
        booking.map((booking) => {
          const DocumentId = booking.DocumentId;

          newState.byID[DocumentId] = booking;

          if (!newState.allIDs.includes(DocumentId))
            newState.allIDs.push(DocumentId);

          return null;
        });

      return newState;
    }
    default: {
      return state;
    }
  }
};
