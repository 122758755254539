import 
  React
from 'react';

/**
 * Components
 */

/**
 * Services
 */
import SSORoutes from '@main/services/navigation/route'

// Styling

const App = () => {
  return (
    <>
      <SSORoutes/>
    </>
  )
}

export default React.memo(App)