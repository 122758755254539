// Third Party Library
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Layout, message, Space } from "antd";

// Constants

// Components
import { CopyRightFooter, LoginHeader } from "../../components";
import { LoginForm } from "./components";

/**
 * Redux Actions
 */
import * as ACTIONS from "@main/services/redux/actions";
import * as REDUX_ACTION from "../../services/redux/actions";
import { TIME } from "../../../v1/services/util";

// Style

const { Header, Content, Footer } = Layout;
const { referenceStartTime, referenceEndOfDay } = TIME;

const SSOLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.root.auth);
  const booking = useSelector((state) => state.containers.main.booking.byID);
  // console.log("booking1", booking);

  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const email = "ajexpress@slim-iot.com";
  const password = "CjSr4eSdjY4aNQ7";
  // const email = "admin@slimiot.dev";
  // const password = 123456789;

  const login_onPress = () => {
    const data = {
      moduleId: 3498,
      logic: "GPS-IOT-Integration-Jobs",
      payload: {
        // startDate: referenceStartTime().format("YYYY-MM-DD"),
        // endDate: referenceEndOfDay().format("YYYY-MM-DD"),
        company: company,
        partialMatch: true,
      },
    };
    dispatch(REDUX_ACTION.main_booking.get_booking_list_request(data));
    dispatch(ACTIONS.main_company.set_company_name(company));

    setLoading(true);
  };

  useEffect(() => {
    auth.currentUser && navigate("/");

    const bookingArray = Object.values(booking);
    const isCompanyValid = bookingArray.some(
      (d) => d.Extension.Company === company
    );
    // console.log("bookingArray", bookingArray);
    // console.log("isCompanyValid", isCompanyValid);

    if (loading && !isCompanyValid) {
      const timer = setTimeout(() => {
        setErrorMessage(
          "Sorry, this company does not exist in our database. Please verify the details and try again."
        );
        setLoading(false);
      }, 10000); // 10 seconds

      return () => clearTimeout(timer);
    }

    if (loading && isCompanyValid) {
      dispatch(
        ACTIONS.main_user.auth_user_sign_in(
          email,
          password,
          ({ status, err }) => {
            if (status === 200) {
              setLoading(false);
            } else if (status === 404) {
              message.error(err);
              setLoading(false);
            }
          }
        )
      );
    }

    return () => {};
  }, [auth, dispatch, booking, company, loading]);

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={[0, 48]}>
      <Layout style={{ backgroundColor: "#ffffff" }}>
        <LoginHeader />

        <Content>
          <LoginForm
            isLoginLoading={loading}
            onLoginPress={(company) => login_onPress(company)}
            company={company}
            setCompany={setCompany}
            errorMessage={errorMessage}
          />
        </Content>

        <Footer style={{ backgroundColor: "#ffffff", textAlign: "center" }}>
          <CopyRightFooter />
        </Footer>
      </Layout>
    </Space>
  );
};

export default SSOLogin;

// // Old code
// const login_onPress = (company) => {
//   dispatch(ACTIONS.main_company.set_company_name(company));
//   dispatch(ACTIONS.main_user.auth_user_sign_in(email, password, ({status, err}) => {
//       if(status === 200) {
//           setLoading(false)
//       } else if (status === 404) {
//           message.error(err)
//           setLoading(false)
//       }
//   }))
//   setLoading(true)
// };

// const login_onPress = (company) => {
//   // const bookingArray = Object.values(booking);
//   // const isCompanyValid = bookingArray.some(
//   //   (d) => d.Extension.Company === company
//   // );

//   const data = {
//     moduleId: 3498,
//     logic: "GPS-IOT-Integration-Jobs",
//     payload: {
//       startDate: referenceStartTime().format("YYYY-MM-DD"),
//       endDate: referenceEndOfDay().format("YYYY-MM-DD"),
//       company: company,
//     },
//   };
//   dispatch(REDUX_ACTION.main_booking.get_booking_list_request(data));
//   dispatch(ACTIONS.main_company.set_company_name(company));

//   if (isCompanyValid) {
//     dispatch(
//       ACTIONS.main_user.auth_user_sign_in(
//         email,
//         password,
//         ({ status, err }) => {
//           if (status === 200) {
//             setLoading(false);
//           } else if (status === 404) {
//             message.error(err);
//             setLoading(false);
//           }
//         }
//       )
//     );

//     setLoading(true);
//   } else {
//     message.error("Invalid company name. Please try again.");
//   }
// };

// useEffect(() => {
//   auth.currentUser && navigate("/");

//   return () => {};
// }, [auth, dispatch]);
