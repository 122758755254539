import * as ActionTypes from "../action-types";

const company_defaultState = {};

export const company = (state = company_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.SET_COMPANY_NAME: {
      const company = action.company;
      newState.company = company;
      return newState;
    }
    default: {
      return state;
    }
  }
};
