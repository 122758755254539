import { initializeApp } from "firebase/app";
import "firebase/messaging";
import "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Config
import { APP_CONFIG } from "../../../config";

// //Initialize Firebase Cloud Messaging (FCM)
export const firebase = initializeApp(APP_CONFIG.FIREBASE);
export const firestore = getFirestore(firebase);
export const auth = getAuth(firebase);
