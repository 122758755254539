import React from 'react'
import {
    Layout
  } from "antd";

// Constants
import { ROOT_CONSTANT } from '../../constants'

const { THEME } = ROOT_CONSTANT
const { Header } = Layout;

const LoginHeader = () => {

    return (
        <Header style={{ backgroundColor: "#ffffff", textAlign: "center", height: 160 }}>
            <img
                alt=""
                src={THEME.LOGO_SIDE_WORD}
                style={{ height: "70%",  marginTop: 50 }}
            />
            <p style={{ marginTop: -20, fontSize: 16 }}>CBVV Platform</p>
        </Header>
    )
}

export default LoginHeader;