import { React } from "react";

// Styles
import classStyles from "./index.module.css";

const CopyRightFooter = (props) => {
  return (
    <div className={classStyles.CopyrightFooter}>
      <p>©️ Copyright of AJ Express Sdn Bhd. 2024</p>
    </div>
  );
};

export default CopyRightFooter;
