// Third Party Library
import { message } from "antd";
import * as ActionTypes from "../action-types";
import * as API from "../../api";

export const get_user_api_hash_request = () => (dispatch) => {
  const email = "ajexpress@slim-iot.com";
  const password = "CjSr4eSdjY4aNQ7";

  API.getUserAPIHash(email, password).then((info) => {
    const { status, msg, user_api_hash } = info;
    if (status === 1) {
      dispatch(get_user_api_hash_success(user_api_hash));
      // message.success("Get User API Hash Successfully");
    } else {
      message.error(msg);
    }
  });
};

const get_user_api_hash_success = (user_api_hash) => {
  return {
    type: ActionTypes.V1_GET_USER_API_HASH,
    user_api_hash,
  };
};
