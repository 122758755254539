import * as API_CONSTANT from "../constants";

export const getBookingList = (items) =>
  fetch("https://api.inistate.com/api/activity/logicservice", {
    method: "POST",
    headers: API_CONSTANT.inistate_headers,
    body: JSON.stringify(items),
  }).then((res) => res.json());

// export const getBookingList = (items) =>
//   fetch(`${API_CONSTANT.inistate_v1_fe_path}`, {
//     method: "POST",
//     headers: API_CONSTANT.inistate_headers,
//     body: JSON.stringify(items),
//   }).then((res) => res.json());

// const items = {
//     "moduleId": 3498,
//     "logic": "GPS-IOT-Integration-Jobs",
//     "payload": {
//         "startDate": "2024-05-13",
//         "endDate": "2024-05-13",
//         "company": "DYSON MANUFACTURING SDN BHD"
//     }
// }
