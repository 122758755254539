
import 
    React,
    {
        useEffect
    }
from 'react'
import {
    useDispatch
} from 'react-redux'
import { 
    onAuthStateChanged,
    getAuth
} from 'firebase/auth'

// Services
import { FIREBASE } from '@src/services/cloud'

// Redux Action
import { 
    set_auth_user_request,
    unset_auth_user_request,
    set_auth_loading_request
} from '@src/services/redux/actions'
import {
    sso_user_sign_in_request
} from '@main/services/redux/actions/user'

export const AuthBackgroundTask = () => {

    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(set_auth_loading_request())

        const listener = onAuthStateChanged(FIREBASE.auth, user => {

            const cuser = user || getAuth().currentUser
            // console.log("cuser", cuser)

            if (cuser) {
                // User is signed in.

                dispatch(set_auth_user_request({currentUser: cuser.uid}))
                dispatch(sso_user_sign_in_request(cuser.uid, 'firebase'))

            } else {
                dispatch(unset_auth_user_request())
            }
        })
        
        return listener
    })

    return <></>
}
