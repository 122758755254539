import * as API from "../../api";
import { message } from "antd";

import * as ActionTypes from "../action-types";
import {
  setLoadingDeviceList,
  setLoadingDeviceLatest,
  unsetLoadingDeviceList,
  unsetLoadingDeviceLatest,
} from "./style";

export const get_all_device_list_request = (user_api_hash) => (dispatch) => {
  dispatch(setLoadingDeviceList());

  API.getDeviceList(user_api_hash)
    .then((info) => {
      const { status, msg, device = info[0].items } = info;
      // console.log("info", info)
      // console.log("device", device)

      // if (status === 200) {
        dispatch(get_all_device_list_success(device));
        // message.success("Get Device List Data Successfully");
      // } else {
      //   message.error(msg);
      // }
    })
    .finally(() => {
      dispatch(unsetLoadingDeviceList());
    });
};

const get_all_device_list_success = (device) => {
  // console.log(device)
  return {
    type: ActionTypes.GET_ALL_DEVICE_LIST,
    device,
  };
};

export const get_device_latest_request = () => (dispatch) => {
  dispatch(setLoadingDeviceLatest());

  API.getDeviceLatest()
    .then((info) => {
      const { status, msg, deviceLatest } = info;
      if (status === 200) {
        dispatch(get_device_latest_success(deviceLatest));
        message.success("Get Device Latest Data Successfully");
      } else {
        message.error(msg);``
      }
    })
    .finally(() => {
      dispatch(unsetLoadingDeviceLatest());
    });
};

const get_device_latest_success = (deviceLatest) => {
  return {
    type: ActionTypes.GET_DEVICE_LATEST,
    deviceLatest,
  };
};
