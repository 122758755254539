import { combineReducers } from "redux";

/**
 * Main reducers
 */

import { style } from "./style";
import { user } from "./user";
import { device } from "./device";
import { user_api_hash } from "./user_api_hash";
import { booking } from "./booking";
import { loadingBooking } from "./loading";

export const v1Reducer = combineReducers({
  style,
  user,
  device,
  user_api_hash,
  booking,
  loadingBooking,
});
